.app {
  display: flex;
  background-color: #8e8e8e;
}

.paper-sidebar {
  z-index: 1;
  height: 65vh;
  width: 55vh;
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  border-bottom-right-radius: 15px;
}

.sidebar {
  z-index: 2;
  background-color: white;
  height: 65vh;
  width: 50vh;
  display: flex;
  flex-direction: column;
}

.padding-top-left {
  padding-left: 20px;
  padding-top: 20px;
}

/* .ff {
  height: 20vh;
  width: 50vh;
  bottom: 0;
  left: 0;
  background-color: green;
  position: absolute;
} */

.paper {
  background-color: white;
  width: 60%;
  height: 90%;
  border-radius: 15px;
}

.instructions {
  z-index: 5;
  color: white;
  background-color: #373737;
  /* border: 2px solid black; */
  width: 15vw;
  height: 5vh;
  text-align: center;
  margin-left: 40vh;
  margin-top: 5vh;
  grid-row: 1;
  grid-column: 1;
  display: flex;
  align-items: center;
  border-radius: 10px;
  /* box-shadow: 0px 0px 4px 8px rgba(0, 183, 255, 0.493); */
}

.innerInstructions {
  text-align: center;
  width: 100%;
}

.typingtext {
  z-index: 1;
  text-align: left;
  white-space: pre;
  font-weight: 700;
  padding-top: 5vh;
  padding-left: 5vh;
  color: #8e8e8e;
  grid-row: 1;
  grid-column: 1;
}

.typingtextfront {
  z-index: 3;
  text-align: left;
  white-space: pre;
  font-weight: 700;
  padding-top: 5vh;
  padding-left: 5vh;
  color: #000000;
  grid-row: 1;
  grid-column: 1;
}

.typingtextbad {
  z-index: 2;
  text-align: left;
  white-space: pre;
  font-weight: 700;
  padding-top: 5vh;
  padding-left: 5vh;
  grid-row: 1;
  grid-column: 1;
  color: #000000;
}

.typingtextbadspan {
  z-index: 2;
  text-align: left;
  white-space: pre;
  font-weight: 700;
  /* padding-top: 5vh;
  padding-left: 5vh; */
  grid-row: 1;
  grid-column: 1;
  color: red;
  background-color: white;
}

.typingcontainer {
  display: grid;
}

.typing {
  height: 100vh;
  width: 75vw;
  /* background-color: #c0c0c0; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.paper-shadow {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.8);
}

.highlight-off {
  outline: none;
}

.highlight-on {
  outline: none;
  box-shadow: 0px 0px 4px 8px rgba(0, 183, 255, 0.493);
}

.score {
  height: 15vh;
  width: 50vh;
}

.username {
  height: 15vh;
  width: 50vh;
}

.wpm {
  background-color: yellow;
}

.menu {
  display: flex;
  align-items: center;
  /* width: 25vw; */
}

.restart {
  /* flex-grow: 1; */
  background-color: teal;
  /* width: 80px; */
}

.next,
.restart {
  /* flex-grow: 0; */
  background-color: turquoise;
  border-radius: 10px;
  height: 40px;
  width: 80px;
}

.spacing-menu {
  flex-grow: 1;
  height: 15vh;
}

.bottom {
  height: 5vh;
  background-color: white;
  width: 50vh;
}

.next {
  background-color: #00e5ff4f;
  color: #ffffff00;
}

.hidden {
  display: none;
}

.padding-left {
  padding-left: 20px;
}

.score-text {
  text-align: right;
  font-size: 60px;
  padding-right: 20px;
}

.green {
  color: #14ca23;
  background-color: #00e5ff4f;
}
