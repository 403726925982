body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
}

.good-font {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary-light {
  background-color: #8e8e8e;
}

.primary-main {
  background-color: #616161;
}

.primary-main-light {
  background-color: #787878;
}
.primary-dark {
  background-color: #373737;
}

.primary-text {
  color: #ffffff;
}

.secondary-light {
  background-color: #6effff;
}

.secondary-main {
  background-color: #00e5ff;
}

.secondary-dark {
  background-color: #00b2cc;
}

.secondary-text {
  color: #000000;
}
